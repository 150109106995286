export const serviceTextConfig = {
    bodyData:[
        {
            index:0, //序号
            imageUrl:require('@/assets/images/desc/icon_iot.png'), //图片链接
            text:'IoT研究開発', //标题
            name:'iot', //选中逻辑
            url:'/service', //跳转链接
            title:'IoT研究開発', //标题
            desc:'モノのインターネット製品のための設備接続、メッセージの送受信、データ管理クラウドサービスです。', //描述
            bgImg:require('../assets/images/desc/banner2.png'), //pc上展示的图
            mgImg:require('../assets/images/desc/banner_mobile.png') //手机上展示的图
        },
        {
            index:1,
            imageUrl:require('@/assets/images/desc/icon_qukuailian.png'),
            text:'ブロックチェイン',
            name:'btc',
            url:'/service/btc',
            title:'ブロックチェイン',
            desc:'「Blockchain Cloud」はブロックチェーンをクラウド上でサービスとして提供するBaaS（Blockchain as a Service、バース）のことです。',
            bgImg:require('../assets/images/btc/banner3.png'),
            mgImg:require('../assets/images/btc/banner3_mb.png')
        },
        {
            index:2,
            imageUrl:require('@/assets/images/desc/icon_xinyongka.png'),
            text:'クレジットカード',
            url:'/service/creditcard',
            name:'creditcard',
            title:'クレジットカード',
            desc:'金融分野の中でもクレジットカードのカード発行業務に必要な統合環境に関する分析・改善から構築・導入までのサービスを提供しています。',
            bgImg:require('../assets/images/credit/banner4.png'),
            mgImg:require('../assets/images/credit/banner4_mb.png')
        },
        {
            index:3,
            imageUrl:require('@/assets/images/desc/icon_xitongkaifa.png'),
            text:'システム開発',
            url:'/service/develop',
            name:'develop',
            title:'システム開発',
            desc:'私どものもつITパートナーネットワークと共に技術サービスの総合力でお客様(一般企業、SIer、IT関連企業など)のITサービス各専門分野を支援します。サービスを提供しています。',
            bgImg:require('../assets/images/develop/banner5.png'),
            mgImg:require('../assets/images/develop/banner5_mb.png')
        },
        {
            index:4,
            imageUrl:require('@/assets/images/desc/icon_car.png'),
            text:'SMARTCAR',
            url:'/service/smartcar',
            name:'smartcar',
            title:'SMARTCAR',
            desc:'専用端末や携帯を利用して、車両の位置情報をリアルタイムで収集し、クラウド上で管理・分析する車両監視システムです。スマートフォまたはPCで車両位置を監視できます。車両軌跡の照会と作業レポートの機能も備えています。',
            bgImg:require('../assets/images/car/banner6.png'),
            mgImg:require('../assets/images/car/banner6_mb.png')
        },
    ]
}

export default {}