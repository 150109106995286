<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" :class="isHome?'navbar-home':'navbar-normal'">
        <a class="navbar-brand" href="#">
            <img v-if="isHome" :src="require('@/assets/images/home/logo_bai.png')" alt="">
            <img v-else :src="require('@/assets/images/desc/logo.png')" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li v-for="(item, index) in navBarData" :key="index" class="nav-item" :class="[isHome && navSelected!==item.index?'nav-item-home':'',navSelected===item.index?'nav-active':'']">
                    <router-link :to="item.url" @click="navSelected=item.index" >
                        <a class="nav-link nav-title">{{item.title}}</a>
                        <a class="nav-link nav-desc">{{item.desc}}</a>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
    
</template>

<script>
export default {
    props:{
        isHome:Boolean
    },
    data(){
        return {
            navSelected:0,
            navBarData:[
                {
                    index:0,
                    title:"ホーム",
                    desc:"Home",
                    name:["home"],
                    url:"/"
                },
                {
                    index:1,
                    title:"業務紹介",
                    desc:"Service",
                    name:["service","info","btc","creditcard","develop","smartcar"],
                    url:"/service"
                },
             /*   {
                    index:2,
                    title:"採用情報",
                    desc:"Recruit",
                    name:["recruit","graduated","policy","social"],
                    url:"/recruit"
                },*/
                {
                    index:3,
                    title:"会社概要",
                    desc:"Company",
                    name:["company","abstract","strategy","instance"],
                    url:"/company"
                },
                {
                    index:4,
                    title:"お問い合わせ",
                    desc:"Contact us",
                    name:["contact"],
                    url:"/contact"
                }
            ]
        }
    },
/* eslint-disable */

    mounted() {
        this.defaultSelectedHandler()
    },
    methods:{
        defaultSelectedHandler(){
            this.navSelected = this.navBarData.find(x=>x.name.includes(this.$route.name)).index
        }
    }
}
</script>

<style>
a:hover{
    text-decoration: none;
}
.navbar-brand img{
    width:calc(100vw/1920*177);
    height:calc(100vw/1920*60);
}
.navbar-home{
    position: absolute;
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    width:100%;
    /* height:80px; */
    background:rgba(255,255,255,0) !important;
}
.nav-active{
    transition: all .3s;
    border-bottom: 4px solid rgba(2,98,255,1) !important;
}
.nav-active a{
    transition: all .3s;
    color:rgba(2,98,255,1) !important;
}
.navbar-collapse{
    flex-grow: 0;
}
.navbar-nav{
    margin-left: 117px;
}
.navbar-normal{
    display: flex;
    justify-content: space-around;
    width:100%;
    /* height:80px; */
    background:rgba(255,255,255,1) !important;
    box-shadow:0px 2px 8px 0px rgba(0,0,0,0.15);
}
.nav-item{
    padding: 15px 0 15px 0;
    margin-right: 54px;
    border-bottom: 4px solid rgba(0, 0, 0, 0);
}
@media screen and (max-width:768px) { 
    
}
.nav-item-home a{
    color: #FFF;
}
.nav-title{
    font-size:16px;
    font-family:YuGothic, "游ゴシック体", "Noto Sans Japanese";
    font-weight:600;
    /* color:rgba(51,51,51,1) !important; */
    line-height:22px;
    padding: 0 0 6px 0;
}
.nav-desc{
    font-size:12px;
    font-family:PingFangSC-Regular, PingFang SC;
    font-weight:400;
    /* color:rgba(51,51,51,1) !important; */
    line-height:17px;
    padding: 0;
}
</style>