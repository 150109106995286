<template>
    <div>
        <NavBar :isHome='false' />
        <div class="container-fluid service-banner" :style="{backgroundImage:'url('+serviceTextConfig.bodyData[subNavSelected][bannerHandler]+')'}">
            <div class="row">
                <p class="service-banner-title">{{serviceTextConfig.bodyData[subNavSelected].title}}</p>
                <p class="service-banner-desc">{{serviceTextConfig.bodyData[subNavSelected].desc}}</p>
            </div>
        </div>
        <div class="sub-nav-container container">
            <div class="row">
                <div @click="subNavClickHandler(item,index)" class="sub-nav-item col" v-for="(item, index) in serviceTextConfig.bodyData" :key="index" :class="subNavSelected===item.index?'nav-active':''">
                    <img draggable="false" :src="item.imageUrl" alt="">
                    <div>{{item.text}}</div>
                </div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import {serviceTextConfig} from "@/config/Service"
export default {
    components:{
        NavBar
    },
    data(){
        return{
            smDesc:'',
            serviceTextConfig:serviceTextConfig,
            subNavSelected:0,
        }
    },
    computed: {
        bannerHandler(){
            if(window.screen.width>768){
                return 'bgImg'
            }else{
                return 'mgImg'
            }
        }
    },
    mounted() {
      this.subNavInit()
    },
    methods: {
        subNavClickHandler(item,index){
            this.subNavSelected=index
            this.$router.push(item.url)
        },
        subNavInit(){
            this.subNavSelected = this.serviceTextConfig.bodyData.find(k => k.name === this.$route.name).index
        }
    },
}
</script>

<style>
@media screen and (max-width:768px) {
    .service-banner{
        height: 125px !important;
        height: calc(100vw/1920*640) !important;
    }
}
.service-banner{
    margin-top: 20px;
    height: calc(100vw/1920*400);
    background-size: contain;
    background-repeat: no-repeat;
}
.service-banner div{
    width: calc(100vw/1920*554);
    text-align: left;
    position: absolute;
    top: calc(100vw/1920*192);
    left:calc(100vw/1920*360);
  }
.service-banner-title{
    font-size:calc(100vw/1920*30);
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:700;
    color:rgba(255,255,255,1);
    line-height:60px;
    text-shadow:0px 2px 2px rgba(0,0,0,0.15);
  }
  .service-banner-desc{
    font-size:calc(100vw/1920*16);
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:32px;
    text-shadow:0px 1px 1px rgba(0,0,0,0.5);
  }
  .sub-nav-container{
      margin-top: 20px;
  }
  .sub-nav-item{
      cursor: pointer;
      margin: 0 calc(100vw/1920*40);
      border-bottom: 4px solid rgba(0, 0, 0, 0);
  }
  .sub-nav-item>div{
    font-size: calc(100vw/1920*18);
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:700;
    color:rgba(51,51,51,1);
    line-height:23px;
    margin: 17px 0;
  }
  .sm-desc{
      display: none
  }
  .service-body{
    background-color: #F5F5F9;
    padding: 0 calc(100vw/1920*157);
    height: 486px;
  }
  .service-body-image{
    width:589px;
    height:359px;
    position: relative;
    bottom: 60px;
  }
  .service-mask-container{
    margin-top:107px;
  }
  .service-body-title{
    font-size:calc(100vw/1920*24);
    padding-top: 45px;
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:700;
    color:rgba(2,98,255,1);
    line-height:33px;
  }
  .service-body>span{
    width:89px;
    height:89px;
    font-size:80px;
    font-family:Arial-BoldMT,Arial;
    font-weight:normal;
    color:rgba(2,98,255,1);
    line-height:92px;
    position: relative;
    /* right: calc(100vw/1920*200); */
    bottom: 60px;
    opacity: 0.1;
  }
  .service-body-ul{
      padding: 0;
  }
  .service-body-ul li{
    display: flex;
    list-style: none;
    text-align: left;
    margin-bottom: 30px;
  }
  .service-body-ul>li>div{
    margin-right: 20px;
  }
  .service-body-ul>li>span{
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:400;
    font-size:16px;
    width: 554px;;
    color:rgba(51,51,51,1);
    line-height:calc(100vw/1920*32);
  }
  .service-body-ul>li>div img{
      width: 22px;
      height: 22px;
  }
  .service-mask-img{
      height:62px;
  } 
  .service-body-sqare{
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 40px;
  }
  .service-body-sqare>div{
      width: 300px;
      height: 390px;
     
  }
  .service-options-desc{
      width:544px;
      text-align:left;
  }
  .service-body-sqare-devider{
   
    /* transform: translate(0, -64px); */
  }
  .service-body-sqare-mask{
    width: 300px;
    height: 390px;
    position: absolute;
    background:rgba(0,0,0,0.49);
  }
  .service-body-sqare-mask:hover{
    background:rgba(2,98,255,0.89);
  }
  .service-body-sqare-text{
    font-size:calc(100vw/1920*24);
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:33px;
    margin-top: 37px;
  }
  .service-body-sqare-desc{
    opacity: 0;
    padding: 20px;
    font-size:calc(100vw/1920*16);
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:calc(100vw/1920*32);
  }
  .service-options-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 200px;
  }
  .service-options-container>div{
    display: flex;
  }
  .service-options-container>div img{
    width: 22px;
  }
  .service-options-container>div div{
    font-size:16px;
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:32px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .service-management-container{
      display: flex;
      position:relative;
      bottom:40px;
  }
  .service-management-container>div{
      width: 50%;
  }
  .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter{
  transform: translateX(100vw);
  opacity: 1;
}
.slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(-100vw);
  opacity: 1;
}
</style>